.container {
  background: black;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.container:after {
  content: "";
  width: 100%;
  height: 100vh;
  background: url("https://i.imgur.com/PsjPzdO.png");
  background-size: 200px;
  mix-blend-mode: overlay;
  position: absolute;
  top: 0;
  left: 0;
}
.container .blobs {
  width: 50vh;
  height: 50vh;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container .blobs .blob {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: absolute;
  background-color: #eac0c8;
  mix-blend-mode: multiply;
  filter: blur(80px);
}
.container .blobs .blob.a {
  background-color: #ac6cff;
  -webkit-animation: circular2 10s linear infinite;
  animation: circular2 10s linear infinite;
}
.container .blobs .blob.b {
  background-color: #9747ff;
  -webkit-animation: circular 5s linear infinite;
  animation: circular 5s linear infinite;
}
.container .blobs .blob.c {
  -webkit-animation: circular 20s linear infinite;
  animation: circular 20s linear infinite;
}

@-webkit-keyframes circular {
  0% {
    transform: translate(0, -150px);
  }
  25% {
    transform: translate(150px, 0);
  }
  50% {
    transform: translate(0, 150px);
  }
  75% {
    transform: translate(-150px, 0);
  }
  100% {
    transform: translate(0, -150px);
  }
}

@keyframes circular {
  0% {
    transform: translate(0, -150px);
  }
  25% {
    transform: translate(150px, 0);
  }
  50% {
    transform: translate(0, 150px);
  }
  75% {
    transform: translate(-150px, 0);
  }
  100% {
    transform: translate(0, -150px);
  }
}
@-webkit-keyframes circular2 {
  0% {
    transform: translate(0, 150px);
  }
  25% {
    transform: translate(-150px, 0);
  }
  50% {
    transform: translate(0, -150px);
  }
  75% {
    transform: translate(150px, 0);
  }
  100% {
    transform: translate(0, 150px);
  }
}
@keyframes circular2 {
  0% {
    transform: translate(0, 150px);
  }
  25% {
    transform: translate(-150px, 0);
  }
  50% {
    transform: translate(0, -150px);
  }
  75% {
    transform: translate(150px, 0);
  }
  100% {
    transform: translate(0, 150px);
  }
}
