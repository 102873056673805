body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #030512;
}

code {
  font-family: "Montserrat Alternates", sans-serif;
}
